/* You can add global theme to this file, and also import other style files */
@import "assets/theme/_vars";
@import "assets/theme/fonts";
@import "assets/theme/google-fonts.scss";
@import "assets/theme/fontawesome-pro/css/all.min.css";
@import "assets/theme/theme";
@import "~@sweetalert2/theme-bootstrap-4/bootstrap-4.min.css";
@font-face {
  font-family: 'Lato Bold';
  src: $latto-bold-ttf;
}
@font-face {
  font-family: 'montserrat';
  src: $montserrat-eot-font;
  src: $montserrat-eot-font format('embedded-opentype'),
       $montserrat-woff2-font format('woff2'),
       $montserrat-font format('woff'),
       $montserrat-ttf-font  format('truetype'),
       $montserrat-svg-font format('svg');
}
@font-face {
  font-family: 'lat med';
  src: $latto-reg-ttf;
}

@font-face {
  font-family: 'lat semi-bold';
  src: $latto-semi-ttf;
}

@font-face {
  font-family: 'lat light';
  src: $latto-light-ttf;
}

@import "assets/theme/texts";
@import "assets/theme/boxes";
@import "assets/theme/buttons";
@import "assets/theme/emojis";

body {
  margin: 0;
  min-height: 100%;
  background-color: #fafbfc;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  line-height: 1.5;
  color: #313140;
  font-size: 12px;
  overflow: hidden;
}

.card {
  webkit-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .1), inset 0 4px 0 0
  #45A9E2;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .1), inset 0 2px 0 0
  #45A9E2;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: none;
}

.card-body {
  padding: 26px 24px 22px;
}

.flex-basis-0 {
  flex-basis: auto !important;
}

.flex-fixed {
  flex: 0 0 auto;
}

.flex-body {
  flex: 0 1 auto;
}

.page-card {
  max-height: 100vh;
  margin-bottom: 0;
  webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: none;
}

.page-card__header {
  max-height: 20vh;
}

.page-card__footer {
  max-height: 10vh;
}

.page-card__body {
  overflow-y: scroll;
  min-height: 60vh;
}



.avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: -0.5rem;
}


.header-btn {
  background-color: transparent;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.5s;
}

.header-btn:hover {
  background-color: rgba(0, 0, 0, .1);
}

.align-center {
  text-align: center;
}

.back-icon {
  width: 9px;
  height: 15px;
}

textarea, input, iframe {
  font-family: intercom-font, "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
}

.btn-primary:hover {
  background-color: $primary-darker-color;
  box-shadow: none;
}


.text-no-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.scroll-div {
  flex: 10 !important;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.scroll-div * {
  overflow-anchor: none;
}

#anchor {
  overflow-anchor: auto;
  bottom: 0;
  height: 1px;
}


.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-bottom: 0;
}

.flex-1 {
  flex: 1 !important;
}

.flex-10 {
  flex: 10 !important;
}

.btn-link {
  color: $primary-color;
}

.btn-link:hover {
  color: $primary-darker-color;
}

.profile-pic {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.username {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $secondary-color;
  font-family: "Lato Bold";
  &.last-conversation-date{
    color: #969696;
    font-size: 10px;
  }
}

.status {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}


//html, body {
//  height: 100%;
//}
//
//body {
//  margin: 0;
//  font-family: Roboto, "Helvetica Neue", sans-serif;
//}

.cdk-overlay-connected-position-bounding-box {
  z-index: 99999 !important;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 9999 !important;
}

.modal-content {
  animation-name: modal-animation;
  animation-duration: 0.5s;
}

@keyframes modal-animation {
  0% {
    transform: translateY(-101%)
  }
  100% {
    transform: translateX(0%)
  }
}

.mat-tooltip {
  color: rgb(255, 255, 255);
  font-size: 12px;
  background: rgb(115, 115, 118);
}

.tip {
  color: rgb(255, 255, 255);
  font-size: 12px;
  background: rgb(115, 115, 118);
  content: attr(tip);
  position: absolute;
  padding: 4px;
  border-radius: 2px;
  opacity: 0;
  z-index: 99999;
}

.tip-holder {
  position: relative;
}

.tip-holder:hover .tip {
  opacity: 1;
}

#chat_container_div{
  height: 80%;
}

.toast-close-button {
  font-weight: normal;
  text-shadow: none;
}
#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
#toast-container > div {
  opacity: 0.8 !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80) !important;
  filter: alpha(opacity=80) !important;
}
.toast-error {
  background-color: #bd362f;
}
#toast-container > :hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

.user_name_photo {
  font-family: FontAwesome;
  font-weight: 600;
  background: #ddd;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 25px;
}
body.swal2-shown {
  padding-right: 0px !important;
}
.swal2-show {
  animation: ease-in-out !important;
}
.modal-backdrop {
  background-color: #4d5b69 !important;
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
