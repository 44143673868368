@import "../theme/vars";
// buttons
.box-header__back-btn:hover {
    // background-color: rgba(0, 0, 0, 0.5);
    i {
      color: #007bbc;
      font-size: 1.3em;
    }
}
.send-btn:hover  {
    background-color: $primary-darker-color;
}
.btn-primary {
  background-color: $primary-color !important;
  border: none;
}

//to apply styles for Safari only
@supports (-webkit-backdrop-filter: blur(1px)) {
    .box .box-body .reverse,
    .box .box-body .content {
        transform :matrix3d(1, 0, 0, 0, 0, -1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1);
    }

    .box .box-body .reverse .content .messages {
        display: -webkit-box !important;
        display: flex !important;
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        flex-direction: column-reverse !important;
    }
}