button {
  font-family: intercom-font, "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
  font-size: 100%;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
  font-weight: normal;
  text-align: left;
  text-align-last: initial;
  text-indent: 0px;
  text-shadow: none;
  text-transform: none;
  background-color: transparent;
  background-image: none;
  box-sizing: content-box;
  line-height: inherit;
  color: inherit;
  min-width: 0px;
  font-variant: normal;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 0px;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.box-header__back-btn {
  margin: 8px 0 8px 8px;
  width: 51px;
  z-index: 1;
  height: 51px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  i {
    color: white;
    font-size: 1.3em;
  }
}


