$latto-light-ttf: url('/assets/fonts/Lato-Light.ttf');
$latto-reg-ttf: url('/assets/fonts/Lato-Regular.ttf');
$latto-med-ttf: url('/assets/fonts/Lato-Medium.ttf');
$latto-semi-ttf: url('/assets/fonts/Lato-Semibold.ttf');
$latto-bold-ttf: url('/assets/fonts/Lato-Bold.ttf');
$latto-black-ttf: url('/assets/fonts/Lato-Black.ttf');
$latto-heavy-ttf: url('/assets/fonts/Lato-Heavy.ttf');

$montserrat-font: url('/assets/fonts/Montserrat-Regular.woff');
$montserrat-eot-font: url('/assets/fonts/Montserrat-Regular.eot');
$montserrat-woff2-font: url('/assets/fonts/Montserrat-Regular.woff2');
$montserrat-ttf-font: url('/assets/fonts/Montserrat-Regular.ttf');
$montserrat-svg-font: url('/assets/fonts/Montserrat-Regular.svg');
