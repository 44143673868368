$chat-font-family: "Lato Bold", Helvetica Neue, Apple Color Emoji, Helvetica, Arial, sans-serif;
body {
  font-family: $chat-font-family;
  font-size: 100%;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
  font-weight: normal;
  text-align: left;
  text-align-last: initial;
  text-indent: 0px;
  text-shadow: none;
  text-transform: none;
  box-sizing: content-box;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  font-variant: normal;
}

h1 {
  font-family: "Lato Bold", "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
  font-size: 100%;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
  font-weight: normal;
  text-align: left;
  text-align-last: initial;
  text-indent: 0px;
  text-shadow: none;
  text-transform: none;
  line-height: inherit;
  font-variant: normal;
  margin-bottom: 0 !important;
}

h2 {
  font-family: "Lato Bold", "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif;
  font-size: 100%;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
  font-weight: normal;
  text-align: left;
  text-align-last: initial;
  text-indent: 0px;
  text-shadow: none;
  text-transform: none;
  line-height: inherit;
  font-variant: normal;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  margin-bottom: 0 !important;
}
