@import "backgrounds";

.box {
  display: flex;
  flex-direction: column;
}

.box-header {
  height: 65px;
  position: relative;
  display: flex;
  align-items: center;
  color: $white-color;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5;
    background-image: $header-background-image;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
    background: $header-background-gradient no-repeat;
    background-size: cover;
  }
}

.box-header__title {
  width: 100%;
  text-align: center;
  position: absolute;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

.box-body {
  position: relative;
  background-color: #F0F4F7;
}
