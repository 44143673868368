@import '@ctrl/ngx-emoji-mart/picker';

.emoji-mart {
  width: 350px !important;
  height: 260px;
  position: absolute;
  bottom: 100%;
  z-index: 999999;
  right: -0.75rem;
  border: none;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 15px 1px;

  &:after {
    content: '  ';
    position: absolute;
    bottom: -8px;
    width: 0;
    height: 0;
    right: 50px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
  }

  .emoji-mart-scroll {
    height: 210px;
    padding: 0 20px 6px 20px;
  }

  .emoji-mart-bar {
    display: none;
  }

  .emoji-mart-search {
    padding: 0 !important;
    margin: 3px 20px;
    display: flex;
    border-bottom: 1px solid rgb(225, 225, 225) !important;
  }

  .emoji-mart-search input {
    height: 40px;
    border: none;
    font-size: 14px;
  }

  .emoji-mart-search button {
    top: 13.5px;
    right: 12px;
  }

  .emoji-mart-category-label {
    position: relative;
    color: rgb(115, 115, 118);
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 1.27;
    margin: 5px;

    span {
      padding: 0;
    }
  }

  .emoji-mart-emoji {
    span {
      cursor: pointer;
      transition: all 200ms ease-in-out;
    }

    &:before {
      display: none;
    }

    &:hover span {
      transform: scale(1.25);
    }
  }
}
